.modal_img,
.warning_img,
.success_img,
.error_img,
.info_img {
  background-position: top left;
  background-repeat: no-repeat;
}

.modal_img {
  background-size: 300px auto;
}

.warning_img {
  background-size: 200px 300px;
}

.success_img {
  background-size: 232px 300px;
}

.error_img {
  background-size: 232px 300px;
}

.info_img {
  background-size: 232px 300px;
}

.loading_gif {
  background-size: 232px 300px;
}

.btn_layout {
  display: flex;
}

.btn_layout_end {
  justify-content: flex-end;
}

.btn_layout_center {
  justify-content: center;
}

.ok_btn,
.cancel_btn {
  flex-basis: 150px;
  height: 50px;
  margin-left: 25px;
  color: white;
}

.cancel_btn,
.cancel_btn:hover {
  background-color: #afafaf;
  border-color: #afafaf;
}

.ok_btn:hover,
.cancel_btn:hover {
  opacity: 0.7;
  color: white;
}

.text_layout {
  margin-top: 170px;
  margin-bottom: 16px;
  margin-left: auto;
  width: 220px;
  height: 170px;
  overflow-y: auto;
  text-align: right;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 9px;
  word-break: break-word;
  line-height: 33px;
}

.content {
  color: #383838;
  font-size: 14px;
  line-height: 28px;
  white-space: pre-line;
}

.react_modal_content {
  border-radius: 25px;
  max-width: 500px;
  height: 500px;
  padding: 40px;
  margin: auto;
  position: absolute;
  inset: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: 8px 8px 15px 10px #00000026;
  outline: none;
}

.react_modal_overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.react_loading_modal {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: 8px 8px 15px 10px #00000026;
  outline: none;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react_loading_modal_title {
  margin-top: 20px;

  font-family: Noto Sans T Chinese;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 1px;
  text-align: center;

}

@media (max-width: 768px) {
  .warning_img,
  .success_img,
  .error_img,
  .info_img {
    background-size: 193px 250px;
  }

  .modal_img {
    background-size: 200px auto;
  }
}