.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 32;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff;
    opacity: 1;
  }

  .loading_title {
    margin-top: 20px;

    font-family: Noto Sans T Chinese;
    font-size: 13px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 1px;
    text-align: center;
  }

  .hide {
    display: none;
  }