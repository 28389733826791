.wrapper {
  width: 100%;
  height: 100%;
}

#mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 9999;
}

.loading_placeHolder {
  position: absolute;
  background-image: url('/src/assets/loading_icon.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px 50px ;
}

.loading_placeHolder_swiper {
  background-image: url('/src/assets/loading_icon.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px 50px ;
}