.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* footer */

.footer {
  z-index: 99;
}

.privacy {
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}
