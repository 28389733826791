.btnContainer {
  position: absolute;
  z-index: 1;
  right: 8px;
}

.line {
  width: 30px;
  height: 30px;
  margin-bottom: 18px;
  background-image: url('../../assets/icon_LINE.svg');
  background-size: contain;
  border-radius: 50%;
  cursor: pointer;
}

.line:hover {
  opacity: 0.7;
}

.fb {
  width: 30px;
  height: 30px;
  margin-bottom: 18px;
  background-image: url('../../assets/icon_fb.svg');
  background-size: contain;
  border-radius: 50%;
  cursor: pointer;
}

.fb:hover {
  opacity: 0.7;
}

.copyLink {
  position: relative;
  width: 30px;
  height: 30px;
  margin-bottom: 18px;
  background-image: url('../../assets/icon_link.svg');
  background-size: contain;
  border-radius: 50%;
  cursor: pointer;
}

.copyLink:hover {
  opacity: 0.7;
}

/* share_btn */
.action_btn_container {
  position: relative;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 12px;
  transform: translateY(-50%);
  width: auto;
  height: auto;
  opacity: 1;
  z-index: 10;
}

.share_btns_container {
  z-index: -1;
  opacity: 0;
  transition: opacity .5s ease-in-out , z-index .1s .5s linear;
  pointer-events: none;
}

.share_btns_container_show {
  z-index: 1;
  opacity: 1;
  transition: opacity .5s ease-in-out , z-index .1s linear;
  pointer-events: auto;
}

.shareLink {
  width: 35px;
  height: 35px;
  background-image: url('/src/assets/mobile_share.png');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 18px;
}
