.modal {
  position: relative;
  z-index: 11;
}

.outerContainer {
  position: fixed;
  bottom: 130px;
  right: 25px;
  z-index: 9;
}

.noLine {
  bottom: 58px;
}

.csBtnContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.horseContainer {
  width: 60px;
  height: 76.4x;
  margin-bottom: 10px;
  margin-right: 5px;
}

.lineBtnContainer {
  position: fixed;
  width: auto;
  height: auto;
  bottom: 90px;
  right: 25px;
  z-index: 10;
}

.fbBtnContainer {
  cursor: pointer;
  display: flex;
  width: 50px;
  height: 50px;
}


.chatting {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.unread {
  position: absolute;
  top: calc(50% + 15px);
  left: calc(50% + 15px);
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  background-color: #EC455A;
  border-radius: 50%;
}