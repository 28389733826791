.container {
  height: 100%;
}

.text {
  position: absolute;
  top: 5%;
  left: 5%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #FFF;
}

.mobileText {
  position: absolute;
  top: 10%;
  left: 5%;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: 2.25px;
  color: #FFF;
}

.more {
  position: absolute;
  bottom: 73px;
  left: 112px;
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
  letter-spacing: 1.69px;
  color: #fff;
  cursor: pointer;
}

.more::before {
  content: "";

  position: absolute;
  left: -85px;
  top: calc(50% - 1px);

  width: 79px;
  height: 1px;
  background: #fff;

}

.container .webSlide {
  width: auto;
}

.container .mobileSlide {
  position: relative;
  width: 80%;
  padding-top: 133.33%;
}

.webSlide {
  margin: 0 40px;
  cursor: pointer;
}

.mobileSlide {
  margin: 0 15% 0 0;
}

.container .webSlide .img {
  width: auto;
  aspect-ratio: 9/16;
}

.container .mobileSlide .img {
  position: absolute;
  top: 0;
  object-fit: cover;
}

.blankBlock {
  width: 1px;
  height: 100%;
  background: transparent;
}


.imgContainer {
  width: auto;
  height: 100%;
  aspect-ratio: 9/16;
}